import { React, useLayoutEffect, useRef } from 'react'
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Controller, Scene } from "react-scrollmagic-r18";
import ImageSequenceDynamic from './Sequence_dynamic';

import { gsap } from "gsap/all";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { motion, useAnimation } from 'framer-motion';

gsap.registerPlugin(ScrollTrigger);

const FadeInWhenVisible = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
        hidden: { opacity: 0, y: 35 }
      }}
    >
      {children}
    </motion.div>
  );
};

export default function ScrollmagicDynamic(props) {
  const [ref] = useInView()
  const headerRef = useRef();
  const containerRef = useRef();

  var headerTween = null;
  var contentTween = null;

  function initGsap() {
    //HEADER
    let localHeaderTween = gsap.fromTo(headerRef.current,
      {
        opacity: 0,
        y: 150,
      },
      {
        scrollTrigger: {
          trigger: headerRef.current,
          start: "0px",
          end: "+=300",
          scrub: true,
          markers: props.markers ?? false,
          toggleActions: "restart pause reverse pause"
        },
        y: 0,
        opacity: 1,
      })
    headerTween = localHeaderTween;

    //CONTENT
    let localContentTween = gsap.fromTo(containerRef.current,
      {
        opacity: 0,
        y: 150,
      },
      {
        scrollTrigger: {
          trigger: containerRef.current,
          start: "200px",
          end: "+=300",
          scrub: true,
          markers: props.markers ?? false,
          toggleActions: "restart pause reverse pause"
        },
        y: 0,
        opacity: 1,
      })
    contentTween = localContentTween;
  };


  /*useEffect(() => {

    setTimeout(() => {
      let ctx = gsap.context(() => {
        let timeline = gsap.timeline({ 
          scrollTrigger: {
            trigger: ".dynamic-container",
            start: "0px",
            end: 'bottom top',
            toggleActions: "play pause reverse pause",
            scrub: true,
            pin: true,
            markers: true
          } 
        });
  
        timeline.fromTo(".dynamic-header", 
        {
          y: 0,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
        });
        timeline.fromTo(".dynamic-content", 
        {
          y: 0,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
        });
      });
  
      return () => ctx.revert();
    }, 200);
  }, []);*/

  var containerClass = props.right ? "dynamic-container-right" : "dynamic-container";
  var containerBoxClass = props.right ? "dynamic-container-box-right" : "dynamic-container-box";
  var boxClass = props.right ? "dynamic-box" : "dynamic-box";

  return (
    <div className={props.gradientClass ?? "bg-dark-light-gradient"}>
      <Controller>
        <Scene duration={props.duration ?? "200%"} triggerHook="0" pin>
          {progress => (
            <div style={{ width: "100%", height: "100vh", position: "relative", display: "flex", alignItems: "center" }}>
              <ImageSequenceDynamic ref={ref} progress={progress} images={props.images} />
              <div className={containerClass}>
                <div className={containerBoxClass}>
                  <FadeInWhenVisible>
                    <div ref={headerRef} className={boxClass + " dynamic-header"}>{props.header}</div>
                  </FadeInWhenVisible>
                  <FadeInWhenVisible>
                    <div ref={containerRef} className={boxClass + " dynamic-content"}>{props.contentBlock}</div>
                  </FadeInWhenVisible>
                </div>
              </div>
            </div>
          )}
        </Scene>
      </Controller>

      {/*<div className={containerClass}>
          <div className={boxClass}>
            <Tween
              from={
                {
                  opacity: 0,
                  y: 0,
                }
              }
              to={{
                y: 0,
                opacity: 1,
                scrollTrigger: {
                  start: '0px',
                  end: 'bottom top',
                  toggleActions: "play pause reverse pause",
                  scrub: true,
                  markers: true,
                },
              }}
            >
              <div ref={headerRef} className={boxClass + " dynamic-header"} style={{ opacity: "0" }}>{props.header}</div>
          </Tween>

          <Tween
              from={
                {
                  opacity: 0,
                  y: 0,
                }
              }
              to={{
                y: 0,
                opacity: 1,
                scrollTrigger: {
                  start: '0px',
                  end: 'bottom top',
                  toggleActions: "play pause reverse pause",
                  scrub: true,
                  markers: true,
                  pin: true
                },
              }}
            >
              <div ref={containerRef} className={boxClass + " dynamic-content"} style={{ opacity: "0" }}>{props.contentBlock}</div>
            </Tween>
          </div>
        </div>*/}
    </div>
  )
}
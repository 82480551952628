import "../style.css";
import { BrowserView, MobileView, } from 'react-device-detect';

export default function FourOne() {

  return (
    <div>
      <div className="container">


      </div>
      <div style={{ display: "flex", margin: "auto" }}>
        <BrowserView>
          <table>
            <tr>
              <td><img src={require("./Images/5-1.jpg")} style={{ height: "600px", opacity: "100%" }}></img></td>
              <h1 className="headline" style={{ paddingLeft: "25px", display: "flex" }}>  Bestandesdaten / Digitales Revierbuch​</h1>
              <td style={{ paddingLeft: "25px", display: "flex" }}>

                <ul style={{ float: "left" }}>
                  <li>
                    Digitales Revierbuch zur Darstellung und flexibler Anpassung von Bestandesdaten.
                  </li>
                  <li>
                    Allgemeine Bestandesbeschreibung
                  </li>
                  <li>
                    Numerische Bestandesbeschreibung
                  </li>
                  <li>
                    Standortsbeschreibung
                  </li>
                  <li>
                    Maßnahmenplanung auf Bestandesebene
                  </li>
                  <li>
                    Arbeitsauftragsanlage direkt aus der Maßnahmenplanung
                  </li>
                  <li>
                    Teilautomatisches befüllen der Vollzüge mit den Ergebnissen des Arbeitsauftrags
                  </li>
                  <li>
                    Datenimport von bestehenden Forsteinrichtungsdaten empfohlen, manuelle Ersterfassung möglich
                  </li>
                </ul>
              </td>
            </tr>
          </table>

        </BrowserView>
        <MobileView>
          <table>
            <tr>
              <td><img src={require("./Images/5-1.jpg")} style={{ height: "auto", width: "30%" }} ></img></td>
            </tr>
            <tr>
              <td style={{ display: "flex", justifyContent: "left", width: "90%" }}>

                <br />

              </td>
              <tr>
                <td style={{ display: "flex", justifyContent: "center", width: "70%" }}>
                  <ul>
                    <li>
                      Digitales Revierbuch zur Darstellung und flexibler Anpassung von Bestandesdaten.
                    </li>
                    <li>
                      Allgemeine Bestandesbeschreibung
                    </li>
                    <li>
                      Numerische Bestandesbeschreibung
                    </li>
                    <li>
                      Standortsbeschreibung
                    </li>
                    <li>
                      Maßnahmenplanung auf Bestandesebene
                    </li>
                    <li>
                      Arbeitsauftragsanlage direkt aus der Maßnahmenplanung
                    </li>
                    <li>
                      Teilautomatisches befüllen der Vollzüge mit den Ergebnissen des Arbeitsauftrags
                    </li>
                    <li>
                      Datenimport von bestehenden Forsteinrichtungsdaten empfohlen, manuelle Ersterfassung möglich
                    </li>
                  </ul>
                </td>
              </tr>
            </tr>


          </table>
        </MobileView>
      </div>
    </div>
  );
}


import { React } from 'react'
import { gsap, ScrollTrigger } from "gsap/all";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { BrowserView, MobileView, } from 'react-device-detect';
import ScrollToTopNoDelay from './Router-Scripts/ScrollToTopNoDelay';


gsap.registerPlugin(ScrollTrigger);

export default function About() {


    return (
        <div >
            <MobileView>
                <table>
                    <tr>
                        <td className='Textside' style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                            <br />

                            <div className='Part3'>
                                <ScrollToTopNoDelay />
                                <h2 style={{ maginBottom: "-40%" }}>UNSERE KRONE</h2>
                                <h2 style={{ fontSize: "18px", maginTop: "-40%" }}>Energieerzeugender Motor</h2>

                                <p>
                                    WOOD.IN.VISION ist Vorreiter eines forstwirtschaftlichen Wandels. Das W.I.V. Team ist davon überzeugt, dass eine nachhaltige wirtschaftliche Entwicklung nur durch das gleichzeitige und gleichberechtigte Umsetzen von umweltbezogenen, sozialen, aber auch wirtschaftlichen Zielen erreicht werden kann.

                                    Wir digitalisieren den Wald!
                                </p>

                            </div>
                            <br></br>
                            <div className='Part2'>
                                <h2>UNSER STAMM</h2>
                                <h2 style={{ fontSize: "18px" }}>Facettenreich & Wertvoll</h2>


                                <p>
                                    Durch die Erfahrung aus dem Wald und die unaufhaltsame Digitalisierung suchten die Brüder einen neuen Weg in der Entwicklung effizienterer Forstwirtschaft. Inspiriert von der Natur, traditionellem Handwerk und neuen Technologien setzt Gebrüder DIEING GbR mit der Software WOOD.IN.VISION auf eine neue Arbeitsgrundlage für das digitale Forstmanagement.
                                    Hierbei vereinigen sich alle Schichten - alle Arbeitsschritte in der Waldbewirtschaftung lassen sich über das System dynamisch abbilden.
                                </p>
                            </div>
                            <br></br>

                            <h2>UNSERE WURZELN</h2>

                            <h2 style={{ fontSize: "18px" }}>Stabil und sicher verankert</h2>

                            <p>
                                Neben seinem landwirtschaftlichen Betrieb arbeitete bereits Großvater Dieing 1960 als forstwirtschaftlicher Lohnunternehmer im Wald.
                                Die drei Brüder Dietmar, Christoph und Manuel Dieing sind daraufhin im elterlichen Holzrückebetrieb aufgewachsen und gründeten nach
                                der Ausbildung/Studium die Firma Gebrüder Dieing GbR. Wood.in.Vision wurde 2021 ins Leben gerufen, mit dem Ziel, alle Arbeitsschritte in der Waldbewirtschaftung
                                in einem System abzubilden.
                            </p>


                        </td>
                    </tr>



                </table>



                <center>
                    <h2 style={{ maginBottom: "-40%" }}>UNSER TEAM</h2>
                    <img src={require("./images/Team.jpg")} width="90%"></img>
                </center>
                <br></br>
                <br></br>
                <center><Link to='https://dieings.de' class="Contact">Zu Dieings</Link></center>
                <br></br>
                <br></br>

            </MobileView>






            <BrowserView>
                <table>
                    <tr>
                        <td className='Tree'>
                            <img src={require("./images/Tree.png")} width="90%"></img>
                        </td>
                        <td className='Textside'>
                            <br />
                            <br />
                            <br />
                            <div className='Part3'>
                                <ScrollToTopNoDelay />

                                <div className="Headline1">UNSERE KRONE</div>
                                <div className="Headline2">Energieerzeugender Motor</div>

                                <br />
                                <div className='Textabout'>
                                    WOOD.IN.VISION ist Vorreiter eines forstwirtschaftlichen Wandels. Das W.I.V. Team ist davon überzeugt, dass eine nachhaltige wirtschaftliche Entwicklung nur durch das gleichzeitige und gleichberechtigte Umsetzen von umweltbezogenen, sozialen, aber auch wirtschaftlichen Zielen erreicht werden kann.
                                    <br></br><br></br>
                                    Wir digitalisieren den Wald!
                                </div>
                            </div>
                            <br></br>
                            <div className='Part2'>
                                <div className="Headline1">UNSER STAMM</div>
                                <div className="Headline2">Facettenreich & Wertvoll</div>

                                <br />
                                <div className='Textabout'>
                                    Durch die Erfahrung aus dem Wald und die unaufhaltsame Digitalisierung suchten die Brüder einen neuen Weg in der Entwicklung effizienterer Forstwirtschaft. Inspiriert von der Natur, traditionellem Handwerk und neuen Technologien setzt Gebrüder DIEING GbR mit der Software WOOD.IN.VISION auf eine neue Arbeitsgrundlage für das digitale Forstmanagement.
                                    Hierbei vereinigen sich alle Schichten - alle Arbeitsschritte in der Waldbewirtschaftung lassen sich über das System dynamisch abbilden.
                                </div>
                            </div>
                            <br></br>

                            <div className="Headline1">UNSERE WURZELN</div>

                            <div className="Headline2">Stabil und sicher verankert</div>

                            <br />
                            <div className='Textabout'>
                                Neben seinem landwirtschaftlichen Betrieb arbeitete bereits Großvater Dieing 1960 als forstwirtschaftlicher Lohnunternehmer im Wald.
                                Die drei Brüder Dietmar, Christoph und Manuel Dieing sind daraufhin im elterlichen Holzrückebetrieb aufgewachsen und gründeten nach
                                der Ausbildung/Studium die Firma Gebrüder Dieing GbR. Wood.in.Vision wurde 2021 ins Leben gerufen, mit dem Ziel, alle Arbeitsschritte in der Waldbewirtschaftung
                                in einem System abzubilden.
                            </div>
                            <br />

                        </td>
                    </tr>



                </table>
                <br></br>
                <br></br>
                <br></br>

                <center>
                    <div className="Headline1">UNSER TEAM</div>
                    <img src={require("./images/Team.jpg")} width="50%"></img>
                </center>
                <br></br>
                <br></br>
                <center><Link to='https://dieings.de' class="Contact">Zu Dieings</Link></center>
                <br></br>
                <br></br>

            </BrowserView>


        </div>
    )

}
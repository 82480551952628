import React, { useRef, useState } from "react";

import "./style.css";
import { Link, NavLink } from "react-router-dom";
import { BrowserView, MobileView, TabletView, } from 'react-device-detect';
import PageScrollProgressBar from "react-page-scroll-progress-bar";

export const Navbar = () => {
  const navbarRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav className="NavbarPosition">
      <Link to="/" className="title">

        <BrowserView>
          <a href="./" ><img src={require("./images/Logo_old.png")} className="nav-logo"></img></a>
        </BrowserView>

      </Link>

      <MobileView style={{ overflow: "hidden", display: "flex", justifyContent: "center", width: "100%" }}>
        <table>
          <tr>
            <td>
              <center><a href="./" ><img src={require("./images/Logo_old.png")} className="nav-logo-mobile"></img></a></center>
            </td>
          </tr>
          <tr>
            <td>
              <ul style={{ justifyContent: "center" }}>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/moduluebersicht">Modulübersicht</NavLink>
                </li>
                <li>
                  <NavLink to="/about">Über uns</NavLink>
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </MobileView>

      <BrowserView>
        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/moduluebersicht">Modulübersicht</NavLink>
          </li>
          <li>
            <NavLink to="/about">Über&nbsp;uns</NavLink>
          </li>
        </ul>
      </BrowserView>
    </nav>
  );
};
import "../style.css";
import { BrowserView, MobileView, } from 'react-device-detect';
import ModalImage from "react-modal-image";

export default function OneTwo() {

  return (
    <div>
      <div className="container">


      </div>
      <div style={{ display: "flex", margin: "auto" }}>


        <BrowserView>
          <div style={{ paddingRight: "8%" }}>
            <table>
              <tr>
                <td>

                  <ModalImage
                    small={require("./Images/1-2.jpg")}
                    large={require("./Images/1-2.jpg")}
                    alt="Hello World!"
                    className	="CarusellImage"
                  />

                </td>
                <h1 className="headline" style={{ paddingLeft: "25px", display: "flex" }}> Kulturbegründung</h1>
                <td style={{ paddingLeft: "25px", display: "flex" }}>
                Die Kulturbegründung dient der detaillierten Planung von Pflanzungen.

                  <br />

                </td>
                <tr>
                  <td>
                    <ul style={{ float: "left" }}>
                    <li>
                        Auftragsdaten
                      </li>
                      <li>
                        Auftraggeber,- und Nehmerinformationen
                      </li>
                      <li>
                        Preistabelle
                      </li>
                      <li>
                        Zeiterfassung
                      </li>
                      <li>
                        Mulcherspurplanung & Aufzeichnung
                      </li>
                      <li>
                        Auftragsfläche
                      </li>
                    </ul>
                  </td>
                </tr>
              </tr>

            </table>
          </div>
        </BrowserView>
        <MobileView>
          <table>
            <tr>
              <td><img src={require("./Images/1-2.jpg")} style={{ height: "auto", width: "30%" }} ></img></td>
            </tr>
            <tr>
              <td style={{ display: "flex", justifyContent: "left", width: "90%" }}>
                Die Kulturbegründung dient der detaillierten Planung von Pflanzungen.

                <br />

              </td>
              <tr>
                <td style={{ display: "flex", justifyContent: "center", width: "70%" }}>
                  <ul>
                    <li>
                      Auftragsdaten
                    </li>
                    <li>
                      Auftraggeber,- und Nehmerinformationen
                    </li>
                    <li>
                      Preistabelle
                    </li>
                    <li>
                      Zeiterfassung
                    </li>
                    <li>
                      Mulcherspurplanung & Aufzeichnung
                    </li>
                    <li>
                      Auftragsfläche
                    </li>
                  </ul>
                </td>
              </tr>
            </tr>


          </table>
        </MobileView>
      </div>
    </div>
  );
}


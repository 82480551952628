import React from 'react'
import Background from './Videos/Background.mp4'
import { useState } from 'react';
import { BrowserView, MobileView, } from 'react-device-detect';
import ScrollToTopNoDelay from './Router-Scripts/ScrollToTopNoDelay';



export default function AGB() {
  const [toggle] = useState(false);


  window.addEventListener("scroll", function () {
  })


  return (
    <div>

      <div className="Datenschutz">
      <ScrollToTopNoDelay />
      <br></br>
      <MobileView>
          <h1>AGB</h1>
        </MobileView>
        <BrowserView>
          <h1 style={{ marginRight: "8%", fontSize: "50px" }}>AGB</h1>
        </BrowserView>
        <br></br>

        <strong>1. Kaufm&auml;nnische Regelungen</strong>

        <p>&nbsp;</p>
        <p><strong>1.1 Spesen und Reisekosten</strong></p>
        <p>Es gelten die jeweiligen g&uuml;ltigen Spesens&auml;tze f&uuml;r das Inland. Bei Auslandseins&auml;tzen werden die Verpflegungspauschalen gem&auml;&szlig; den jeweils g&uuml;ltigen Steuervorschriften der jeweiligen L&auml;nder abgerechnet.</p>
        <p>Anfallende Spesen und Reisekosten sind im Angebotspreis nicht enthalten. Basis f&uuml;r die Berechnung der Reisekosten ist 88316 Isny / Deutschland.<br />Reisekosten pro Kilometer: 0,79 &euro;.</p>
        <p>&Uuml;bernachtungen sowie Taxi, Bahn- und Flugreisen werden nach Aufwand abgerechnet.</p>
        <p>F&uuml;r Sonn- und Feiertagsarbeiten gem&auml;&szlig; der gesetzlichen Feiertage in Baden-W&uuml;rttemberg werden entsprechende Zuschl&auml;ge erhoben. Erstrecken sich Eins&auml;tze &uuml;ber Feiertage oder Wochenenden, ist der Mitarbeiter zur Heimfahrt berechtigt. Die Kosten hierf&uuml;r k&ouml;nnen berechnet werden.</p>
        <p>Als Regelarbeitszeit pro Tag gelten 8 Stunden.</p>
        <p>&nbsp;</p>
        <p><strong>1.2 Zahlungsbedingungen</strong></p>
        <p>S&auml;mtliche genannten Verg&uuml;tungen sind Nettopreise. Sie verstehen sich zuz&uuml;glich der jeweils zum Zeitpunkt der Rechnungslegung geltenden gesetzlichen Mehrwertsteuer. Die Mehrwertsteuer kann bei l&auml;nder&uuml;bergreifenden Lieferungen entfallen (Reverse Charge). Rechnungstellung ist 50% bei Auftragsbest&auml;tigung, 50% bei Lieferung der Hard- und Software.</p>
        <p>Das Zahlungsziel betr&auml;gt 14 Tage nach Rechnungsstellung. Es gelten die gesetzlichen Regelungen zu den Folgen des Zahlungsverzugs.<br /><br /></p>
        <p>&nbsp;</p>
        <strong> 2. Systemservice</strong>
        <p>Sind Systemserviceleistungen vereinbart, erbringt WOOD.IN.VISION&reg; &ndash; im Folgenden &bdquo;der Auftragnehmer&ldquo; &ndash; diese nach Ma&szlig;gabe der Vereinbarungen im Support-Vertrag.</p>
        <p>Die Vereinbarungen aus einem Support-Vertrag sind hierbei vorrangig.</p>
        <p>&nbsp;</p>
        <p><strong>2.1 Wiederherstellung der Betriebsbereitschaft (St&ouml;rungsbeseitigung)</strong></p>
        <p>Zur Wiederherstellung der Betriebsbereitschaft gehören die für die Störungsbeseitigung notwendigen Ma&szlig;nahmen des Auftragnehmers soweit nichts anderes vereinbart ist. Dies umfasst z.B. Instandsetzungsleistungen für Hardware und Pflegeleistungen für Standardsoftware zur Beseitigung von Störungen.</p>
        <p>&nbsp;</p>
        <p><strong>2.1.1 Servicezeiten</strong></p>
        <p>Sind keine Servicezeiten im Supportvertrag vereinbart, gelten die Zeiträume von Montag bis Freitag von 9:00 Uhr bis 15:00 Uhr (mit Ausnahme der gesetzlichen Feiertage am Erfüllungsort) als Servicezeiten.</p>
        <p>&nbsp;</p>
        <p><strong>2.1.2 Wiederherstellungszeiten</strong></p>
        <table width="100%" border="border:1px solid white">
          <thead>
            <tr>
              <td width="39%">
                <p><strong>M&auml;ngelklasse</strong></p>
              </td>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="39%">
                <p>Betriebsverhindernder Mangel</p>
              </td>

            </tr>
            <tr>
              <td width="39%">
                0                <p>Betriebsbehindernder Mangel</p>
                0,             </td>

            </tr>
            <tr>
              <td width="39%">
                <p>Leichter Mangel</p>
              </td>

            </tr>
          </tbody>
        </table>
        <br />
        <table width="100%" border="border:1px solid white">
          <thead>
            <tr>

              <td width="31%">
                <p><strong>Reaktionszeit</strong></p>
              </td>

            </tr>
          </thead>
          <tbody>
            <tr>

              <td width="31%">
                <p>6 Stunden</p>
              </td>

            </tr>
            <tr>

              <td width="31%">
                <p>24 Stunden</p>
              </td>

            </tr>
            <tr>

              <td width="31%">
                <p>48 Stunden</p>
              </td>

            </tr>
          </tbody>
        </table>
        <br />
        <table width="100%" border="border:1px solid white">
          <thead>
            <tr>

              <td width="31%">
                <p><strong>Wiederhestellungszeit</strong></p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>

              <td width="31%">
                <p>24 Stunden</p>
              </td>
            </tr>
            <tr>

              <td width="31%">
                <p>48 Stunden</p>
              </td>
            </tr>
            <tr>

              <td width="31%">
                <p>96 Stunden</p>
              </td>
            </tr>
          </tbody>
        </table>


        <p>Wiederherstellungszeiten beziehen sich auf regul&auml;re Werktage.</p>
        <p>&nbsp;</p>
        <p><strong>2.2 Support Definitionen</strong></p>
        <p>Mit dem Standard-Support werden nachfolgend beschriebene Supportleistungen durch die WOOD.IN.VISION&reg; geleistet:</p>
        <p>Fehler in der Software werden nach den in Punkt 2.1.2 definierten Zeiten und M&auml;ngelklasse behoben.</p>
        <p>Meldung dieser Fehler via E-Mail an: <u>support@wood-in-vision.com</u>.</p>
        <p>Gerichtsverwertbare Speicherung der Daten f&uuml;r 5 Jahre.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <strong>3. Allgemeine Regelungen</strong>
        <p><strong>&nbsp;</strong></p>
        <p><strong>3.1 Urheberrechte</strong></p>
        <p>Soweit bei Durchf&uuml;hrung dieses Vertrages durch Leistungen vom Auftragnehmer urheberrechtlich gesch&uuml;tzte Werke entstehen, stehen s&auml;mtliche Rechte daraus dem Auftragnehmer zu.</p>
        <p>&nbsp;</p>
        <p><strong>3.2 Vertraulichkeit und Datenschutz</strong></p>
        <p>Beide Parteien verpflichten sich gegenseitig, Know-How und Betriebsgeheimnisse, die sie bei der Durchf&uuml;hrung dieses Vertrages &uuml;bereinander erfahren und alles Know-How, das nicht allgemein bekannt ist, gegen&uuml;ber Dritten geheim zu halten.</p>
        <p>Die Vertragspartner halten die gesetzlichen Bestimmungen des Datenschutzes ein und werden ihre Mitarbeiter zur Verschwiegenheit verpflichten sowie die zur Vertragserf&uuml;llung eingeschalteten Dritten auf diese Verpflichtungen hinweisen.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>3.3 Haftung</strong></p>
        <p>Der Auftragnehmer haftet für Vorsatz und grobe Fahrlässigkeit. Der Auftragnehmer haftet nicht für mangelnden wirtschaftlichen Erfolg, entgangenen Gewinn, mittelbare Schäden, Mangelfolgeschäden und Ansprüche Dritter. Der Betrag eines Schadensersatzes sowie eines Ersatzes vergeblicher Aufwendungen ist auf den Auftragswert des Schadensjahres begrenzt.</p>
        <p>Die o.g. Haftungsbeschr&auml;nkungen gelten sinngem&auml;&szlig; auch zugunsten der Mitarbeiter und Beauftragten der Auftragnehmer.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>3.4 Vertragsschluss, Vertragslaufzeit und K&uuml;ndigung</strong></p>
        <p>1) Der Vertrag kommt durch Annahme des Vertragsangebots des Kunden seitens WOOD.IN.VISION&reg; zustande. Die Annahme wird entweder ausdr&uuml;cklich erkl&auml;rt oder ist im Beginn der Ausf&uuml;hrung der Leistung durch WOOD.IN.VISION&reg; zu sehen.</p>
        <p>2) Soweit nicht anders vereinbart, betr&auml;gt die Mindestvertragslaufzeit 12 Monate und die Frist f&uuml;r die ordentliche K&uuml;ndigung sechs Wochen zum Ende der Vertragslaufzeit. Falls nicht abweichend vereinbart, verl&auml;ngert sich der Vertrag mangels K&uuml;ndigung um die jeweilige Mindestvertragslaufzeit. Ist diese l&auml;nger als ein Jahr, jedoch jeweils nur um ein Jahr.</p>
        <p>3) Unber&uuml;hrt bleibt das Recht beider Parteien zur au&szlig;erordentlichen K&uuml;ndigung aus wichtigem Grund. Ein wichtiger Grund liegt f&uuml;r den Provider insbesondere vor, wenn der Kunde</p>
        <p>(a) mit der Zahlung der Entgelte mit einem Betrag in H&ouml;he von zwei monatlichen Grundentgelten in Verzug ger&auml;t;</p>
        <p>(b) schuldhaft gegen eine wesentliche Vertragspflicht verst&ouml;&szlig;t, und der Kunde trotz Abmahnung innerhalb angemessener Frist nicht Abhilfe schafft.</p>
        <p>(4) Jede K&uuml;ndigung bedarf zu ihrer Wirksamkeit der Schriftform. Sie k&ouml;nnen auch per E-Mail k&uuml;ndigen, wenn diese die der elektronischen Form des &sect; 126a BGB gen&uuml;gt (sog. qualifizierte elektronische Signatur). Verbraucher im Sinne des &sect; 13 BGB k&ouml;nnen abweichend hiervon stets auch in Textform k&uuml;ndigen.</p>
        <p>&nbsp;</p>
        <p><strong>3.5 Gerichtsstand und Erf&uuml;llungsort, anzuwendendes Recht</strong></p>
        <p>Gerichtsstand und Erf&uuml;llung ist, sofern rechtlich zul&auml;ssig, D-88316 Isny im Allg&auml;u.</p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>&nbsp;</strong></p>
        <strong>4. Salvatorische Klausel</strong>
        <p>Die Unwirksamkeit einzelner Bestimmungen dieses Vertrages hat nicht die Unwirksamkeit dieses Vertrages und der &uuml;brigen Bestimmungen zur Folge.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <strong>5. Schlussbemerkung</strong>
        <p>Die Angebotsg&uuml;ltigkeit betr&auml;gt vier (4) Wochen ab Angebotsdatum. Nach Ablauf dieser Frist behalten wir uns vor, ein neues Angebot vorzulegen. Mit der Bestellung anerkennt der Auftraggeber die in diesem Angebot aufgef&uuml;hrten Regelungen. Vor Auslieferung der Produkte und Projektstart setzen wir einen schriftlichen Eingang der Bestellung und unsere Auftragsbest&auml;tigung voraus.<br /><br /></p>
        <p>Sofern bereits zu einem fr&uuml;heren Zeitpunkt Angebote in gleichem Zusammenhang von dem Auftragnehmer erstellt wurden, verlieren diese mit diesem Angebot ihre G&uuml;ltigkeit. Dieses Angebot, sowie die hierin von dem Auftragnehmer vorgestellten Inhalte, Konzeptionen und L&ouml;sungen, sind Eigentum des Auftragnehmers und d&uuml;rfen durch den Empf&auml;nger dieses Angebotes nicht im Rahmen dieses oder eines anderen Projektes verwendet werden. Dieses Angebot darf nur mit schriftlicher Zustimmung seitens des Auftragnehmers an Dritte weitergegeben werden.</p>
        <p>Bis zur vollst&auml;ndigen Bezahlung, verbleiben alle Rechte und Leistungsergebnisse komplett bei dem Auftragnehmer.</p>
        <p>&nbsp;</p>


      </div>
    </div>

  )
}
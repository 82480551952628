import "../style.css";
import { BrowserView, MobileView, } from 'react-device-detect';


export default function OneFiveOne() {

  return (
    <div>
      <div className="container">


      </div>
      <div style={{ display: "flex", margin: "auto" }}>

        <BrowserView>
          <table>
            <tr>
              <td><img src={require("./Images/1-5-1.jpg")} style={{ height: "600px" }}></img></td>
              <h1 className="headline" style={{ paddingLeft: "25px", display: "flex" }}> Holzernte mechanisiert</h1>
              <td style={{ paddingLeft: "25px", display: "flex" }}>
                Komplette Holzernte in einem Auftrag

                <br />

              </td>
              <tr>
                <td>
                  <ul style={{ float: "left" }}>
                    <li>
                      Auftragsdaten
                    </li>
                    <li>
                      Auftraggeber,- und Nehmerinformationen
                    </li>
                    <li>
                      Preistabelle
                    </li>
                    <li>
                      Zeiterfassung
                    </li>
                    <li>
                      Schadholzmonitoring
                    </li>
                    <li>
                      Digitale Rückegassenplanung und Aufzeichnung
                    </li>
                    <li>
                      Verortete Lager,- und Polterplätze
                    </li>
                    <li>
                      Produktionsdaten in Echtzeit abrufbar
                    </li>
                    <li>
                      Manuelle Holzaufnahme
                    </li>
                  </ul>
                </td>
              </tr>
            </tr>


          </table>


        </BrowserView>
        <MobileView>
          <table>
            <tr>
              <td><img src={require("./Images/1-5-1.jpg")} style={{ height: "auto", width: "30%" }} ></img></td>
            </tr>
            <tr>
              <td style={{ display: "flex", justifyContent: "left", width: "90%" }}>
                Komplette Holzernte in einem Auftrag
                <br />

              </td>
              <tr>
                <td style={{ display: "flex", justifyContent: "center", width: "70%" }}>
                  <ul>
                    <li>
                      Auftragsdaten
                    </li>
                    <li>
                      Auftraggeber,- und Nehmerinformationen
                    </li>
                    <li>
                      Preistabelle
                    </li>
                    <li>
                      Zeiterfassung
                    </li>
                    <li>
                      Schadholzmonitoring
                    </li>
                    <li>
                      Digitale Rückegassenplanung und Aufzeichnung
                    </li>
                    <li>
                      Verortete Lager,- und Polterplätze
                    </li>
                    <li>
                      Produktionsdaten in Echtzeit abrufbar
                    </li>
                    <li>
                      Manuelle Holzaufnahme
                    </li>
                  </ul>
                </td>
              </tr>
            </tr>


          </table>
        </MobileView>
      </div>
    </div>
  );
}


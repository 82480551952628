import { React, useEffect, useState } from 'react';
import { useInView } from "react-intersection-observer";
import { gsap, ScrollTrigger } from "gsap/all";
import "./Video.css";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import LogoSlider from "./Slider";
import { BrowserView, MobileView } from 'react-device-detect';
import MobileHome from './MobileHome';
import { Controller, Scene } from "react-scrollmagic-r18";
import ScrollmagicDynamic from './components/Scrollmagic_dynamic';
import ImageArray1 from "./Sequence/ImageArray";
import ImageArray2 from "./Sequence2/ImageArray2";
import ImageArray3 from "./Sequence3/ImageArray3";
import ImageArray4 from "./Sequence4/ImageArray4";
import ImageArray5 from "./Sequence5/ImageArray5";
import { motion, useAnimation } from 'framer-motion';
import ScrollToTop from './Router-Scripts/ScrollToTop';

gsap.registerPlugin(ScrollTrigger);

const LazyImage = ({ src, alt, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoaded(true);
  }, [src]);

  return (
    <img
      src={loaded ? src : 'placeholder.jpg'}
      alt={alt}
      {...props}
      style={{ opacity: loaded ? 1 : 0.5, transition: 'opacity 0.5s' }}
    />
  );
};

const FadeInWhenVisible = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
        hidden: { opacity: 0, y: 35 }
      }}
      style={{ willChange: 'transform, opacity' }}
    >
      {children}
    </motion.div>
  );
};

export default function Home() {
  const [ref] = useInView();

  var contentBlock = <font className="secondrow">WOOD.IN.VISION ermöglicht eine dynamische Betriebssteuerung <br></br>für die nächste Generation der Forstwirtschaft.<br></br>Wir denken in digital gestützten Prozessen, entwickeln <br></br> und optimieren umfassende Anwendungen mit und für unsere Kunden. <br /><br></br> Soft- und Hardware entwickelt von und für Forstprofis.  <br /> <b>Erfahren. Nachhaltig. Effizient. </b><br></br><br></br><Link to='/kontakt' className="Contact">Jetzt Anfragen</Link><br /><div style={{ paddingTop: "10px" }}><Link to='https://www.magazin-allgaeu.de/arbeitenimallgaeu/woodinvision/' target="_blank" class="Contact">Artikel</Link></div></font>;
  var content2Block = <font className="secondrow">WOOD.IN.VISION ist eine digitale Plattform,<br></br>  die cloud-basiert betrieben wird. Der Zugriff<br />  erfolgt lokal über den Web-Browser und mobil <br />  mit über den Web-Browser und mobil <br /> mit der W.I.V-App. </font>;
  var content3Block = <font class="secondrow">  WOOD.IN.VISION ist herstellerunabhängig <br></br> und die Lösung für unterschiedliche <br />Maschinenflotten. Ob auf dem<br />  Maschinen-PC oder mobil <br />über Smartphone und Tablet.</font>;
  var content4Block = <font class="secondrow"> WOOD.IN.VSION ist die Lösung für die zusammen.<br></br> Die Projektdetails zeigen zum Beispiel offene <br></br>Aufträge, Zeiten und Umsätze zum  <br></br> gesamten Forstbetrieb an.  <br></br> <br></br> <Link to='https://www.lrakn.de/service-und-verwaltung/aemter/kreisforstamt/digitale+fortschritte+bei+der+holzernte+-+wood+in+vision' target="_blank" class="Contact">Artikel</Link> </font>;
  var content5Block = <font class="secondrow">  WOOD.IN.VISION fügt alle Einträge auf Dashboards<br></br> die unterschiedliche Tätigkeiten im Forst planen, <br /> umsetzen, dokumentieren und abrechnen wollen.</font>;

  return (
    <div className="wrapper">
      <MobileView>
        <MobileHome />
      </MobileView>

      <BrowserView>
        <ScrollToTop />
        <ScrollmagicDynamic
          id={1}
          header={<div><p>WIR DIGITALISIEREN </p><br></br><p style={{ marginTop: "-160px", marginBottom: "-1px" }}> DEN WALD </p></div>}
          contentBlock={contentBlock}
          images={ImageArray1()}
          markers={true}
        />

        <ScrollmagicDynamic
          id={2}
          header={<div><p>WIR VERBINDEN</p><br></br><p style={{ marginTop: "-160px", marginBottom: "-1px" }}> ALLE TEILNEHMER </p></div>}
          contentBlock={content2Block}
          images={ImageArray2()}
          gradientClass={"bg-light-dark-gradient"}
        //markers={true}
        />

        <ScrollmagicDynamic
          id={3}
          header={<div><p>WIR VERBINDEN</p><br></br><p style={{ marginTop: "-160px", marginBottom: "-1px" }}> MASCHINEN </p></div>}
          contentBlock={content3Block}
          right
          images={ImageArray3()}
        //markers={true}
        />

        <ScrollmagicDynamic
          id={4}
          header={<div><p>WIR DIGITALISIEREN</p><br></br><p style={{ marginTop: "-160px", marginBottom: "-1px" }}> DIE FEINERSCHLIEßUNG </p></div>}
          contentBlock={content4Block} 
          right
          images={ImageArray4()}
          gradientClass={"bg-light-dark-gradient"}
        //markers={true}
        />

        <ScrollmagicDynamic
          id={5}
          header={<div><p>WIR HABEN </p><br></br><p style={{ marginTop: "-160px", marginBottom: "-1px" }}>ALLES IM BLICK </p></div>}
          contentBlock={content5Block}
          right
          images={ImageArray5()}
        //markers={true}
        />

        <div className="bg-light-dark-gradient">
          <div style={{ width: "100%", height: "100vh", position: "relative", display: "flex", alignItems: "center" }}>
            <div className="dynamic-container">
              <FadeInWhenVisible>
                <div className="dynamic-box a-header"><div><p>WIR DENKEN  </p><br></br><p style={{ marginTop: "-160px", marginBottom: "-1px" }}>LANGFRISTIG UND KUNDENNAH </p></div></div>
                <div className="dynamic-box a-content">
                  <font className="secondrow">
                    Unser Antrieb ist es mit WOOD.IN.VISION eine zukunftsfähige Plattform mit <br />
                    digitalen Anwendungen bereit zu stellen, die eine ressourcenschonende,  <br />
                    prozessorientierte, langfristig dokumentierbare und auf den Kreislaufgedanken <br />
                    basierte Waldbewirtschaftung ermöglichen. Wir handeln aus Überzeugung im <br />
                    Sinne einer ökologisch und ökonomisch nachhaltigen Forstwirtschaft.
                  </font>
                </div>
              </FadeInWhenVisible>
              <FadeInWhenVisible>
                <div className="dynamic-box-text-right">
                  <div className="b-header"><div><p>FORSTWIRTSCHAFT DER </p><br></br><p style={{ marginTop: "-160px", marginBottom: "-1px" }}> N&Auml;CHSTEN GENERATION </p></div></div>
                  <div className="b-content">
                    <font className="secondrow">
                      WOOD.IN.VISION ist mobil on- und offline nutzbar. Alle Daten sind auch unter <br />
                      schwierigen Bedingungen, dank unserer Hardware georeferenziert. <br />
                      Die Cloud-Technologie ermöglicht es uns, alle Teilnehmer in der Forstwirtschaft <br />
                      digital und sicher miteinander zu vernetzen. Wir schaffen damit eine auf <br />
                      Transparenz und nahezu Echtzeit basierende Zusammenarbeit. Jeder Teilnehmer <br />
                      entscheidet dabei selbst, welche Daten zur Verfügung gestellt werden.
                    </font>
                  </div>
                </div>
              </FadeInWhenVisible>
            </div>
          </div>
        </div>

        <h1 className="headline"><center>UNSERE KUNDEN</center></h1>
        <LogoSlider />

        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <h1 className="headline"><center>UNSERE PARTNER</center></h1>
        <center>
          <table>
            <tr>
              <td>
                <a href="https://wahlersforsttechnik.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo14.png")} width="300" height="auto" /></a>
                <a href="https://www.musts.io/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo12.png")} width="300" height="auto" /></a>
                <a href="https://www.hs-rottenburg.net/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo8.png")} width="300" height="auto" /></a>
              </td>
            </tr>
          </table>
        </center>

        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>

        <h1 className="headline"><center>UNSERE PAKETE</center></h1>
        <div className='Pakete'>
          <table style={{ borderSpacing: "30px", width: "100%" }}>
            <tr>
              <td style={{ width: "calc(100%/3)", marginLeft: "30%", backgroundColor: "" }}>
                <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                  <h1 style={{ fontSize: "40px", textAlign: "center" }}>Cloud Basic</h1>
                  <h2 style={{ fontSize: "20px", fontWeight: "300", textAlign: "center", marginTop: "-7%" }}>für Unternehmer</h2>
                  <LazyImage className="" src={require("./images/Angebot.png")} alt="" width="300" height="278" />
                  <center><Link to='/kontakt' className="Contact">Jetzt Anfragen</Link></center>
                  <ul style={{ textAlign: "left" }}>
                    <li style={{ listStyleType: "none" }}>
                      <ul>
                        <li style={{ listStyleType: "none" }}>
                        </li>
                      </ul>
                      <br></br>
                    </li>
                  </ul>
                </div>
              </td>

              <td style={{ width: "calc(100%/3)", marginRight: "30%", backgroundColor: "" }}>
                <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                  <h1 style={{ fontSize: "40px", textAlign: "center" }}>Cloud Advanced</h1>
                  <h2 style={{ fontSize: "20px", fontWeight: "300", textAlign: "center", marginTop: "-7%" }}>für Forstservices</h2>
                  <LazyImage className="" src={require("./images/Angebot.png")} alt="" width="300" height="278" />
                  <center><Link to='/kontakt' className="Contact">Jetzt Anfragen</Link></center>
                  <ul style={{ textAlign: "left" }}>
                    <li style={{ listStyleType: "none" }}>
                      <ul>
                        <li style={{ listStyleType: "none" }}>
                        </li>
                      </ul>
                      <br></br>
                    </li>
                  </ul>
                </div>
              </td>

              <td style={{ width: "calc(100%/3)", backgroundColor: "" }}>
                <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                  <h1 style={{ fontSize: "40px", textAlign: "center" }}>Cloud Professional</h1>
                  <h2 style={{ fontSize: "20px", fontWeight: "300", textAlign: "center", marginTop: "-7%" }}>für Waldbesitzer</h2>
                  <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                    <LazyImage className="" src={require("./images/Angebot.png")} alt="" width="300" height="278" />
                    <center><Link to='/kontakt' className="Contact">Jetzt Anfragen</Link></center>
                    <ul style={{ textAlign: "left" }}>
                      <li style={{ listStyleType: "none" }}>
                        <ul>
                          <li style={{ listStyleType: "none" }}>
                          </li>
                        </ul>
                        <br></br>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <h1 className="headline"><center>MEHR ERFAHREN </center></h1>

        <link
          rel="stylesheet"
          href=""
        />

        <div>
          <center><iframe style={{ width: "80vw", height: "calc(80vw/16*9)", maxWidth: "1280px", maxHeight: "720px" }} src="https://www.youtube-nocookie.com/embed/RRV6iKB8W7s?si=1B2Xl_vktJoRGiTY" title="Das ist Wood.in.Vision | Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></center>
        </div>

        <br></br>
        <center><Link to='https://drive.google.com/uc?export=download&id=1WIkwMxzKIbvqxmZivlVSaoBZuxe3C2C1' className="Contact">W.I.V. Broschüre Herunterladen</Link></center>
        <br></br>
        <center><Link to='https://drive.google.com/uc?export=download&id=1OuV9BLq_lVnLnlwA0i0F3A6fRi4HID12' className="Contact">W.I.V. Starter Flyer Herunterladen</Link></center>
        <br></br>
      </BrowserView>
    </div>
  );
}

import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import "./style.css";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import OneOne from './ProductPageItems/1-1';
import OneTwo from './ProductPageItems/1-2';
import OneFive from './ProductPageItems/1-5';
import OneFiveOne from './ProductPageItems/1-5-1';
import TwoOne from './ProductPageItems/2-1';
import TwoThree from './ProductPageItems/2-3';
import ThreeOne from './ProductPageItems/3-1';
import ThreeFour from './ProductPageItems/3-4';
import ThreeFive from './ProductPageItems/3-5';
import FourZero from './ProductPageItems/4-0';
import FourOne from './ProductPageItems/4-1';
import ThreeThreeOne from './ProductPageItems/3-3-1';
import { BrowserView, MobileView, } from 'react-device-detect';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScrollToTopNoDelay from './Router-Scripts/ScrollToTopNoDelay';




// Styled component for the colored bar indicators
const ColoredBar = styled('div')(({ theme, color }) => ({
  width: '10px',
  height: '70px',
  backgroundColor: color,
  marginLeft: '0px',
  transform: "rotate(15deg)",

}));

// Styled component for the tab with a container for colored bars
const CustomTab = styled((props) => <Tab {...props} scrollButtons
  allowScrollButtonsMobile variant="scrollable" />)(({ theme }) => ({
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',

    },
    '& .MuiTabs-flexContainer': {

      overflow: 'overlay',
    },
  }));



// Component for tabs with colored indicators
function ColoredIndicatorTabs({ value, handleChange, tabsConfig }) {
  return (

    <Box sx={{ width: "100%", margin: "auto" }} >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs value={value} variant="scrollable" onChange={handleChange} aria-label="tabs with colored bars" TabIndicatorProps={{
          style: {
            display: 'none'
          }
        }}>
          {tabsConfig.map((tab, index) => (
            <CustomTab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  {tab.label}
                  <Box sx={{ display: 'flex', marginLeft: '20px' }}>
                    {tab.colors.map(
                      (color, index) => color && <ColoredBar key={index} color={color.color} />
                    )}
                  </Box>
                </Box>
              }
              key={index}
            />
          ))}
        </Tabs>
      </Box>
      <Typography component="div" p={3} backgroundColor="#2B2A2A">
        {tabsConfig[value] ? tabsConfig[value].content : null}
      </Typography>
    </Box>

  );
}


var businessowner = {
  name: "businessowner",
  color: "#009900"
}

var forstryservice = {
  name: "forstryservice",
  color: "#5A595B"
}

var landowner = {
  name: "landowner",
  color: "#A2A297"
}


// Main App component
function Forstunternehmer() {
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState(0);
  const [value4, setValue4] = useState(0);
  const [value5, setValue5] = useState(0);


  const [filterActive, setFilterActive] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([
    businessowner,
    forstryservice,
    landowner,
  ]);

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const handleChange3 = (event, newValue) => {
    setValue3(newValue);
  };

  const handleChange4 = (event, newValue) => {
    setValue4(newValue);
  };

  const handleChange5 = (event, newValue) => {
    setValue5(newValue);
  };

  const tabsConfig1 = [
    { label: 'Flächenbearbeitung', colors: [businessowner, forstryservice, landowner], content: <OneOne /> },
    { label: 'Kulturbegründung', colors: [businessowner, forstryservice, landowner], content: <OneTwo /> },
    { label: 'Auftrag Forst universal', colors: [businessowner, forstryservice, landowner], content: <OneFive /> },
    { label: 'Holzernte mechanisiert', colors: [businessowner, forstryservice, landowner], content: <OneFiveOne /> },
  ];

  const tabsConfig2 = [
    { label: 'Rückegassenplanung', colors: [businessowner, forstryservice, landowner], content: <TwoOne /> },
    { label: 'Seiltrassenplanung', colors: [businessowner, forstryservice, landowner], content: <TwoThree /> },
  ];

  const tabsConfig3 = [
    { label: 'Vermessung / Flurstückserfassung', colors: [forstryservice, landowner], content: <ThreeOne /> },
    { label: 'Habitatbäume', colors: [landowner], content: <ThreeThreeOne /> },
    { label: 'Baumkataster​', colors: [businessowner, forstryservice], content: <ThreeFour /> },
    { label: 'Verkehrssicherheit', colors: [landowner], content: <ThreeFive /> },
  ];

  const tabsConfig4 = [
    { label: 'Jagdmanagement​', colors: [landowner], content: <FourZero /> },
  ];

  const tabsConfig5 = [
    { label: 'Bestandesdaten / Digitales Revierbuch', colors: [forstryservice, landowner], content: <FourOne /> },
  ];


  function setFilterValues(type) {
    if (!filterActive) {
      var newFilters = [type]
      setFilterActive(true)
      setSelectedFilters(newFilters)
    } else {
      var newFilters = [...selectedFilters]
      if (newFilters.includes(type)) {
        const index = newFilters.indexOf(type);
        newFilters.splice(index, 1);
      } else {
        newFilters.push(type)
      }

      if (newFilters.length == 0) {
        setFilterActive(false)
        newFilters = [
          businessowner,
          forstryservice,
          landowner,
        ]
      }
      setSelectedFilters(newFilters)
    }
  }

  function containsFilter(type) {
    return selectedFilters.includes(type)
  }

  function addRemoveButton(type) {
    if (filterActive && containsFilter(type)) {
      return (
        <CloseIcon />
      );
    } else {
      return (null);
    }
  }


  function getSelectedTabs(tabConfig) {
    var newTabs = []
    tabConfig.map((tabConfig) => {
      selectedFilters.map((selectedFilter) => {
        if (tabConfig.colors.includes(selectedFilter) && !newTabs.includes(tabConfig)) {
          newTabs.push(tabConfig)
        }
      })
    })
    return newTabs;
  }

  return (
    <div>
      <div className="container">
        <br />
        <ScrollToTopNoDelay />

        <MobileView>

          <h2 style={{ marginLeft: "8%", marginRight: "8%" }}>MODULÜBERSICHT</h2>

          <div style={{ marginLeft: "8%", marginRight: "8%" }}>Pro Cloud Paket stehen Module zur Auswahl.

            Viele der Module sind miteinander verbunden. Zum Beispiel ist es möglich, Vollzugsdaten aus der Kultursicherung in die Forsteinrichtung zu schreiben.

            Einfache, kartenbasierte App-Funktionen (z.B. Flächenvermessung).

            Organisationsübergreifender Datenaustausch möglich (z.B. Forstbetrieb/Forstunternehmer).
          </div>

        </MobileView>
        <BrowserView>

          <h1 style={{ marginLeft: "8%", marginRight: "8%", fontSize: "50px" }}>MODULÜBERSICHT</h1>

          <div style={{ marginLeft: "8%", marginRight: "8%", fontSize: "30px", fontWeight: "200" }}>Pro Cloud Paket stehen Module zur Auswahl.

            Viele der Module sind miteinander verbunden. Zum Beispiel ist es möglich, Vollzugsdaten aus der Kultursicherung in die Forsteinrichtung zu schreiben.

            Einfache, kartenbasierte App-Funktionen (z.B. Flächenvermessung).

            Organisationsübergreifender Datenaustausch möglich (z.B. Forstbetrieb/Forstunternehmer).
          </div>

        </BrowserView>

      </div>
      <br></br>
      <br></br>
      <div style={{ display: "flex", marginLeft: "8%", marginRight: "8%" }}>
        <MobileView>
          <h2>1.0 Aufträge</h2>
          <div>
            <Accordion sx={{ bgcolor: '#5B595A', color: "white" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"

              >
                <Typography>Flächenbearbeitung</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2B2A2A' }}>
                <Typography>
                  <OneOne />
                </Typography>
              </AccordionDetails>
            </Accordion>


            <Accordion sx={{ bgcolor: '#5B595A', color: "white" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Kulturbegründung</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2B2A2A' }}>
                <Typography>
                  <OneTwo />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ bgcolor: '#5B595A', color: "white" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography>Auftrag Forst universal</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2B2A2A' }}>
                <Typography>
                  In Entwicklung. Q2/24 verfügbar.
                </Typography>
              </AccordionDetails>
            </Accordion>


            <Accordion sx={{ bgcolor: '#5B595A', color: "white" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                <Typography>Holzernte Mechanisiert</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2B2A2A' }}>
                <Typography>
                  <OneFiveOne />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <br />
          <h2>2.0 Erschließung</h2>
          <div>
            <Accordion sx={{ bgcolor: '#5B595A', color: "white" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography>Rückegassenplanung</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2B2A2A' }}>
                <Typography>
                  <TwoOne />
                </Typography>
              </AccordionDetails>
            </Accordion>


            <Accordion sx={{ bgcolor: '#5B595A', color: "white" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                <Typography>Seilstrassenplanung</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2B2A2A' }}>
                <Typography>
                  <TwoThree />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <br />
          <h2>3.0 Vermessung & Dokumentation</h2>
          <div>
            <Accordion sx={{ bgcolor: '#5B595A', color: "white" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>Vermessen / Flurstückerfassung</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2B2A2A' }}>
                <Typography>
                  <ThreeOne />
                </Typography>
              </AccordionDetails>
            </Accordion>


            <Accordion sx={{ bgcolor: '#5B595A', color: "white" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Habitatbäume</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2B2A2A' }}>
                <Typography>
                  <ThreeThreeOne />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ bgcolor: '#5B595A', color: "white" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography>Baumkataster</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2B2A2A' }}>
                <Typography>
                  <ThreeFour />
                </Typography>
              </AccordionDetails>
            </Accordion>


            <Accordion sx={{ bgcolor: '#5B595A', color: "white" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                <Typography>Verkehrssicherheit</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2B2A2A' }}>
                <Typography>
                  <ThreeFive />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <br />
          <h2>4.0 Jagdmanagement​</h2>
          <div>
            <Accordion sx={{ bgcolor: '#5B595A', color: "white" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                <Typography>Jagdmanagement</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2B2A2A' }}>
                <Typography>
                  <FourZero />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <br />
          <h2>5.0 Forsteinrichtung / Revierbuch</h2>
          <div>
            <Accordion sx={{ bgcolor: '#5B595A', color: "white" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                <Typography>Bestandesdaten / Digitales Revierbuch</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2B2A2A' }}>
                <Typography>
                  <FourOne />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <br /><br />
          <h2> War nichts passendes dabei? <br /> Sprechen Sie uns an.<br /> </h2>
          <Link to='/kontakt' class="Contact">Jetzt Anfragen</Link>
          <br></br>
          <br></br>
          <br></br><br></br>
        </MobileView>



        <BrowserView>
          <div style={{ alignItems: "center" }}>
            <table>
              <tr>
                <td><center><Link to='#' class="FilterButton1" onClick={() => setFilterValues(businessowner)}>Unternehmer{addRemoveButton(businessowner)}</Link></center></td>
                <td><center><Link to='#' class="FilterButton2" onClick={() => setFilterValues(forstryservice)}>Forstservice{addRemoveButton(forstryservice)}</Link></center></td>
                <td><center><Link to='#' class="FilterButton3" onClick={() => setFilterValues(landowner)}>Waldbesitzer{addRemoveButton(landowner)}</Link></center></td>
              </tr>
            </table>


            <br /><br /><br />
            <h1 className="headline" style={{ marginRight: "8%" }}>1.0 Aufträge</h1>
            <ColoredIndicatorTabs value={value1} handleChange={handleChange1} tabsConfig={getSelectedTabs(tabsConfig1)} />
            <br />
            <br />
            <h1 className="headline" style={{ marginRight: "8%" }}>2.0 Erschließung</h1>
            <ColoredIndicatorTabs value={value2} handleChange={handleChange2} tabsConfig={getSelectedTabs(tabsConfig2)} />
            <br />
            <br />
            <h1 className="headline" style={{ marginRight: "8%" }}>3.0 Vermessung & Dokumentation</h1>
            <ColoredIndicatorTabs value={value3} handleChange={handleChange3} tabsConfig={getSelectedTabs(tabsConfig3)} />
            <br />
            <br />
            <h1 className="headline" style={{ marginRight: "8%" }}>4.0 Jagdmanagement​</h1>
            <ColoredIndicatorTabs value={value4} handleChange={handleChange4} tabsConfig={getSelectedTabs(tabsConfig4)} />
            <br />
            <br />
            <h1 className="headline" style={{ marginRight: "8%" }}>5.0 Forsteinrichtung / Revierbuch</h1>
            <ColoredIndicatorTabs value={value5} handleChange={handleChange5} tabsConfig={getSelectedTabs(tabsConfig5)} />
            <br />
            <h1 className="headline" style={{ marginRight: "8%" }}> War nichts passendes dabei? <br /> Sprechen Sie uns an.<br /> </h1>
            <Link to='/kontakt' class="Contact" style={{ marginRight: "8%" }}>Jetzt Anfragen</Link>
            <br></br>
            <br></br>
          </div>
        </BrowserView>
      </div>
    </div>

  );
}

export default Forstunternehmer;